import { ControllerParams, CreateControllerFn } from '@wix/yoshi-flow-editor';
import { GroupsPageController } from './controllers/GroupsPageController';

const createController: CreateControllerFn = async (
  controllerContext: ControllerParams,
) => {
  console.info('YO migration groups createController');
  // For more info about controller structure,
  // check the docs: https://bo.wix.com/pages/yoshi/docs/editor-flow/structure-api/component#controller
  return new GroupsPageController(controllerContext);
};

export default createController;
